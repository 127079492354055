<template>
  <router-view />
</template>

<script>
// import MainPage from './view/home_page.vue'

export default {
  name: 'App',
  components: {}
}
</script>

<style>
* {
  margin: 0;
  padding: 0;
}
html, body {
  min-height: 100%;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  /* margin-top: 60px; */
  background-color: #F8F8F8;
  min-height: 100vh;
}
#back {
  cursor: pointer;
}
</style>

import axios from 'axios';

// axios.defaults.baseURL = 'https://leadsget.top'; // 设置基础 URL

// 获取 CSRF token
const getCookie = (name) => {
  let cookieValue = null;
  if (document.cookie && document.cookie !== '') {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if (cookie.substring(0, name.length + 1) === name + '=') {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
};
axios.defaults.headers.common['X-CSRFToken'] = getCookie('csrftoken');

// 配置请求拦截器
axios.interceptors.request.use(
  (config) => {
    // 在这里可以添加请求头、处理请求参数等
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// 配置响应拦截器
axios.interceptors.response.use(
  (response) => {
    // 在这里处理响应数据，例如统一处理错误码等
    return response;
  },
  (error) => {
    // 在这里处理响应错误
    return Promise.reject(error);
  }
);

export default axios;

import { createApp } from "vue";
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import App from "./App.vue";
import router from "./router";
import "element-plus/theme-chalk/display.css";
import axios from "./utils/http";
// import axios from 'axios';
import * as ElementPlusIconsVue from "@element-plus/icons-vue";
const app = createApp(App);
app.config.globalProperties.$http = axios;
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component);
}
router.afterEach((to) => {
  const defaultTitle = "Default Title";
  document.title = to.meta.title || defaultTitle;
});
app.use(ElementPlus);
app.use(router);
app.mount("#app");

import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    name: "HomePage",
    // component: () => import("../view/home_page.vue"),
    redirect: "/autoquoteusa",
  },
  {
    path: "/autoquoteusa",
    name: "AutoQuoteUSA",
    children: [
      {
        path: "",
        name: "home",
        component: () => import("../view/car_insurance_home.vue"),
        meta: {
          title: "Auto Auote USA",
        },
      },
      {
        path: "start",
        name: "start",
        component: () => import("../view/car_insurance_start.vue"),
        meta: {
          title: "Start Qutote",
        },
      },
      {
        path: "thanks",
        name: "thanks",
        component: () => import("../view/car_insurance_thanks.vue"),
        meta: {
          title: "Thank You",
        },
      },
    ],
  },
  {
    path: "/terms",
    name: "Terms",
    component: () => import("../view/terms.vue"),
  },
  {
    path: "/privacy",
    name: "Privacy",
    component: () => import("../view/privacy.vue"),
  },
  {
    path: "/HomeImprovement",
    component: () => import("../view/router_index.vue"),
    children: [
      {
        path: "",
        name: "HomeImprovement",
        component: () => import("../view/home_improvement.vue"),
      },
      {
        path: "getbest-bathroomremodel",
        name: "getbest-bathroomremodel",
        component: () => import("../view/gutters.vue"),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
